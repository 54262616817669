.sliderContainer {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.headerLogo {
    display: flex;
    margin-bottom: 30px;
    margin-right: 100px;
}

.headerLogo img {
    cursor: pointer;
}

.profileSlider {
    font-family: sans-serif;
    font-size: 40px;
    font-weight: 100;
    line-height: 40px;
    letter-spacing: 1px;
    color: var(--white);
    text-align: left;
}

.companyName {
    font-family: sans-serif;
    font-size: 27px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0;
    color: var(--white);
    text-align: left;
}

.burgerButton {
    position: fixed;
    top: 20px;
    float: right;
}

.chevronBtn {
    background: var(--yellowColor) !important;
}

.footer {
    z-index: 1;
    box-shadow: 40px 40px 50px 40px rgba(0, 0, 0, 0.2);
    border-top: 2px solid #c5c5c5;
}

.footerEmail {
    padding-left: 50px;
    color: var(--white);
}

.footerPhoneContent,
.footerEmailContent {
    font-family: sans-serif;
    font-size: 17px;
    padding-left: 10px;
    font-weight: 400;
    line-height: 10px;
    color: var(--white);
}

.footerEmailContent {
    font-style: italic;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 0;
}

.icon {
    top: 5px;
    position: relative;
}
