.flexibleDemand {
    width: 100%;
}

.flexibleDemand_Container {
    width: 600px;
    background: var(--white);
    border-radius: 16px;
    overflow-y: auto;
    padding: 40px;
    position: relative;
    margin: 0 auto 30px;
}

.flexibleDemand_header img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.flexibleDemand_context {
    height: 72px;
    width: 520px;
    margin-top: 10px;
}

.flexibleDemand_context p {
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: var(--normalTextColor);
}

.formContainer {
    width: 520px;
    position: relative;
}

.flexibleDemand_form {
    width: 520px;
    height: unset;
    border-radius: 16px;
    background-color: var(--lightGray);
    margin-top: 30px;
    padding: 20px;
}

.formHeading,
.scheduleHeading {
    height: 28px;
    width: 199px;
    font-family: inherit;
    font-size: 22px;
    font-weight: 900;
    line-height: 28px;
    letter-spacing: 0.004em;
    text-align: left;
    color: var(--yellowColor);
    margin-bottom: 30px;
}

.scheduleHeading {
    margin-top: 40px;
}

.flexibleDemand_DatePicker,
.flexibleDemand_TimePicker {
    background: var(--white);
}

.schedule {
    height: unset;
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.schedule > div:last-child {
    width: 40%;
    margin-left: 30px;
}

.flexibleDemand_btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
}

.btn {
    width: 100px;
    height: 56px;
    border-radius: 8px;
    padding: 16px 20px 16px 20px;
    border: none;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--normalTextColor);
    text-align: center;
    cursor: pointer;
}

.backBtn {
    background-color: var(--lightBlue);
}

.orderBtn {
    background-color: var(--yellowColor);
}

.orderBtn__disabled {
    opacity: 0.5;
}

.inputGroup___wrapper {
    background-color: var(--white);
    padding-right: 12px;
}

.infoMessage {
    background: #d1e8f0;
    margin-top: 10px;
    padding: 3px;
    display: flex;
}

.infoIcon {
    color: #235461;
    position: relative;
    padding: 2px;
}

.headerContainer {
    display: flex;
}

.crossIcon {
    width: 18%;
    display: flex;
    margin-top: 4px;
    justify-content: flex-end;
}
