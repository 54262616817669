.container {
    width: 100%;
}

.summary {
    width: 600px;
    height: 868px;
    background: var(--white);
    border-radius: 16px;
    overflow-y: auto;
    padding: 40px;
    position: relative;
    margin: 0 auto 30px;
}

.summary_header {
    width: 520px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.summary_header h3 {
    height: 36px;
    width: 268px;
    font-family: inherit;
    font-size: 28px;
    font-weight: 900;
    line-height: 36px;
    letter-spacing: 0;
    text-align: left;
    color: var(--yellowColor);
}

.summary_header img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.summary_content {
    height: 96px;
    width: 520px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: var(--normalTextColor);
    margin-top: 30px;
}

.summaryCards {
    width: 520px;
    height: 520px;
    overflow-y: auto;
}

.flexibleDemand_btn {
    display: flex;
    width: 400px;
    height: 64px;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    gap: 20px;
    bottom: 7%;
    right: 7%;
}

.btn {
    width: 100px;
    height: 56px;
    border-radius: 8px;
    padding: 16px 20px 16px 20px;
    border: none;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--normalTextColor);
    text-align: center;
    cursor: pointer;
}

.backBtn {
    background-color: var(--lightBlue);
}

.orderBtn {
    background-color: var(--yellowColor);
}

.nextWork_Error {
    font-family: inherit;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--redColor);
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexibleDemand_addBtn {
    width: 520px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.flexibleDemand_addBtn button {
    cursor: pointer;
    height: 56px;
    width: 202px;
    border-radius: 8px;
    padding: 16px 20px 16px 20px;
    border: 1px solid var(--normalTextColor);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--normalTextColor);
}

