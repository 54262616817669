.btn {
  width: 190px;
  height: 64px;
  border-radius: 8px;
  padding: 16px 20px 16px 20px;
  border: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--normalTextColor);
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.backBtn {
  background-color: var(--lightBlue);
  width: 100px;
  height: 56px;
}
.orderBtn {
  background-color: var(--yellowColor);
  padding: 0;
  width: 137px;
  height: 56px;
}
