.orderNumber{
    width: 25%;
    display: flex;
}
.purchaseOrderNumber{
    width: 45%;
    padding-left: 6px;
    display: flex;
}
.company{
    width: 30%;
    display: flex;
}