.header__title {
    padding: 20px;
    display: flex;
}

.header__title h1 {
    height: 56px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 48px;
    font-weight: 900;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    color: var(--yellowColor);
    margin-bottom: 20px;
}

.btn {
    border-radius: 8px;
    background: var(--yellowColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.btn_Disabled {
    opacity: 0.5;
}

.btnContainer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    width: 30%;
}

.btnBackward {
    padding: 3px;
    margin-right: 10px;
    width: 50px;
}

.btnForward {
    padding: 3px;
    width: 50px;
}

.staticCostTitle {
    padding: 20px;
    display: flex;
}

.staticCostTitle h1 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 48px;
    font-weight: 900;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    color: var(--yellowColor);
    margin-bottom: 20px;
    width: 70%;
}

.placeOrderNextButton {
    border-radius: 8px;
    padding: 8px;
    background: var(--lightBlue);
    color: var(--normalTextColor);
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.placeOrderNextButton {
    background: var(--yellowColor);
    color: var(--normalTextColor);
}

.addStaticCostButton {
    text-align: end;
    margin: 14px 14px 0 0;
    width: 30%;
}

.flexibleDemand_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexibleDemand_header h1 {
    height: 36px;
    width: 100%;
    font-family: inherit;
    font-size: 44px;
    font-weight: 900;
    line-height: 36px;
    margin-bottom: 10px;
    letter-spacing: 0;
    text-align: left;
    color: var(--yellowColor);
}

.bigFontSize h1 {
    font-size: 48px !important;
}

.indicator {
    width: 4%;
    top: -7px;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    float: left;
}
