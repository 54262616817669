.errorPage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorPage__container {
  width: 480px;
  height: 640px;
  background: var(--white);
  border-radius: 16px;
  padding: 40px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}
.errorPage__heading {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 34px;
  font-weight: 400;
  line-height: 52px;
  text-align: left;
  color: var(--textColorDark);
}
.errorPage__subheading {
  font-family: inherit;
  color: var(--yellowColor);
}
.errorPage__login {
  text-decoration: none;
  width: 120px;
  height: 32px;
  border-radius: 8px;
  border: none;
  background: var(--yellowColor);
  color: var(--normalTextColor);
  font-family: inherit;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.editModalBtn {
  width: 100px;
  height: 56px;
  display: flex;
  align-items: center;
  margin-left: auto;
}
