.moreDemand {
    width: 100%;
    height: 150px;
    border-radius: 16px;
    background-color: var(--lightGray);
    border: 1px solid #efeff0;
    margin: 10px 0;
    padding: 20px 30px;
    position: relative;
    display: flex;
}

.moreDemandShowAll {
    height: 251px;
}

.moreDemandLeft {
    width: 10%;
    padding-top: 10px;
}

.moreDemandRight {
    width: 90%;
    display: flex;
}

.moreDemandTop {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 33.33%;
    font-family: inherit;
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    text-align: left;
    color: var(--textColorDark);
}

.moreDemandTop span {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--brightGray);
}

.moreDemandTop h4 {
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
}

.moreDemandMiddle {
    width: 54%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: var(--normalTextColor);
}

.moreDemandBottom {
    width: 18%;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.moreDemandBottom h4 {
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
}

.moreDemandMiddle span {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--brightGray);
}

.moreDemandBottom img {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--brightGray);
    cursor: pointer;
}

.moreDemandTopAll,
.moreDemandMiddleAll {
    height: 200px;
}

.line {
    width: 298px;
    border: 1px solid #d9dddf;
    position: absolute;
    top: 54%;
}

.toHidden {
    visibility: hidden;
}

.startTimeBold {
    font-weight: 600;
    width: 230px;
}

.moreDemandMiddle small {
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: var(--normalTextColor);
}
