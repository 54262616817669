.btn, .firstBtn, .buttonLogout {
    text-decoration: none;
    width: 350px;
    margin-left: 27px;
    border-radius: 8px;
    padding: 16px 20px 16px 20px;
    background: transparent;
    color: var(--white);
    font-size: 24px;
    line-height: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 20px;
}

.firstBtn {
    background: #efc415;
    color: #1e3a4f;
    font-weight: 700;
}

.firstBtn:after {
    content: '\2192';
    display: inline-block !important;
}

.btn:hover, .buttonLogout:hover {
    background: #efc415;
    color: #1e3a4f;
}

.buttonLogout {
    background: transparent;
    color: #efc415;
}

.buttonHome {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 12px;
}

.icon_color {
    color: var(--white);
}
