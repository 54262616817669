.header {
    width: 100%;
    height: 120px;
    padding: 30px;
    display: flex;
    justify-content: flex-end;
    top: 0;
    z-index: 99;
    align-items: center;
}

.headerProfile {
    width: 149px;
    height: 40px;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.profileName {
    font-family: inherit;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    color: var(--yellowColor);
    text-align: left;
}

.head {
    box-shadow: none !important;
    background-color: transparent;
    position: absolute;
}

.placeOrderNextButton {
    border-radius: 8px !important;
    background: var(--yellowColor) !important;
    color: var(--normalTextColor) !important;
    border: none !important;
    cursor: pointer !important;
    font-family: inherit !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.notificationIcon {
    top: -9px;
    left: 8px;
    position: relative;
    cursor: pointer;
}
