.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-height: 350px;
  border-radius: 16px;
  background-color: var(--white);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 24px;
}

.modalHeaderContainer {
  padding-bottom: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.crossIcon {
  cursor: pointer;
  margin-top: 15px;
}

.button {
  border-radius: 8px;
  padding: 8px;
  border: none;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  background: var(--yellowColor);
  color: var(--normalTextColor);
}

.popOverText {
  color: var(--normalTextColor);
  padding: 5px;
}

.dataGridStyleFirstColumn {
  padding-left: 30px;
  color: var(--normalTextColor);
}

.normalDataGridFontStyle {
  color: var(--normalTextColor);
}

.orderStatusStyle {
  color: var(--normalTextColor);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 2px 12px 2px 12px;
}

.cursorPosition {
  cursor: pointer;
}

.modalPosition {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  background-color: var(--white);
  box-shadow: 0 11px 15px -7px rgba(0,0,0,0.2), 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12);
  padding: 32px;
}
