.datePicker {
  width: 100%;
}
.datePicker .datePicker__withError {
  border-bottom: 2px solid var(--redColor);
}
.datePicker__Column {
  flex-direction: row;
  align-items: center;
  height: 66px;
  padding: 0 12px 10px 12px;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--grayColor);
  display: flex;
  justify-content: space-between;
}
.datePicker__Column_Disabled {
  flex-direction: row;
  align-items: center;
  background-color: #f4f4f4;
  height: 66px;
  padding: 0 12px 10px 12px;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--grayColor);
  display: flex;
  justify-content: space-between;
}

.datePicker__DateInput {
  width: 100px;
  height: 46px;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  border: none;
  outline: none;
  color: var(--normalTextColor);
}
.datePicker__DateInput::placeholder {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--textColorDark);
}
.datePicker__DateCalendarIcon {
  width: 24px;
  height: 24px;
}
.datePicker__placeHolder {
  color: var(--textColorDark);
  font-size: 12px;
  line-height: 16px;
  z-index: 99;
  font-weight: 700;
  position: relative;
}
.datePicker__placeHolder_withError {
  color: var(--redColor);
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.datePicker__textAndInput {
  height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
